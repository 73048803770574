import request from '@/plugins/axios'

// 礼品卡列表
export const apiGetGiftcardList = (params: any) =>
    request.get('/gift.giftcard/lists', { params })

// 添加礼品卡
export const apiAddGiftcard = (params: any) =>
    request.post('/gift.giftcard/add', params)

// 删除礼品卡
export const apiDelGiftcard = (id: any) =>
    request.post('/gift.giftcard/del', id)

// 获取一种礼品卡列表
export const apiGetGiftcardListList = (params: any) =>
    request.get('/gift.giftcard/cardLists', { params })

// 礼品卡领取记录
export const apiDrawList = (params: any) =>
    request.get('/gift.giftcard/drawList', { params })
