










































































































import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import {
    apiStorehouseDel,
    apiStorehouseStatus, // 修改仓库状态
    apiStorehouseList // 仓库列表
} from '@/api/goods'
import { apiGetGiftcardList, apiDelGiftcard } from '@/api/marketing/gift_card'
@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class Category extends Vue {
    pager = new RequestPaging()
    apiGetGiftcardList = apiGetGiftcardList
    getList(page?: number): void {
        console.log(this.pager)
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiGetGiftcardList,
            params: {}
        })
    }
    handleStatus(value: number, row: object) {
        let params: any = Object.create(null)
        params = row
        params.status = value
        apiStorehouseStatus(params).then(() => {
            this.getList()
        })
    }

    handleDelete(id: number) {
        apiDelGiftcard({ id }).then(() => {
            this.getList()
        })
    }

    created() {
        this.getList()
    }
}
